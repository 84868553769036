import { apiURL, staticURL } from '../api/api';

export const generateKey = (length = 8) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let res = '';

  while (res.length < length) {
    res += chars[Math.floor(Math.random() * chars.length)];
  }
  return res;
};

export const normFile = e => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

export const buildImageURL = filename => [staticURL, 'img', filename].join('/');

export const getBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
