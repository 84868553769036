import React, { useEffect } from 'react';
import ReactQuill from 'react-quill';

interface ITextEditorProps {
  value?: string;
  onChange?: (...args) => any;
}

const TextEditor: React.FC<ITextEditorProps> = ({ value, onChange }) => {
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'code'],
      ['link'],
      ['clean'],
      [{ 'direction': 'rtl' }]
    ]
  };

  return (
    <ReactQuill
      theme="snow"
      value={value ?? ''}
      onChange={onChange}
      modules={modules}
    />
  );
};

export default TextEditor;
