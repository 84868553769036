import api from "./api";
import { IChat } from "../types/chats";

export const fetchChats = async (searchedObject = {}): Promise<IChat[]> => {
  const { data } = await api.get("/chats", {
    params: {
      searchedObject,
    },
  });
  return data.chats;
};

export const fetchChatOptions = async (): Promise<{
  basises: String[];
  products: String[];
}> => {
  const { data } = await api.get("/chats/options");
  return data;
};

export const updateChat = async (
  id: string,
  data: Partial<IChat>
): Promise<void> => {
  await api.post(
    "/chats/update",
    {
      id,
      data,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteChats = async (ids: string[]): Promise<void> => {
  await api.delete('/chats', {
    params: ids,
  })
};

export const exportChats = async (data: File): Promise<void> => {
  let formData = new FormData();
  formData.append("file", data, "myfile.xlsx");
  await api.post("/excel", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const importChats = async (searchedObject: any): Promise<any> => {
  const response = await api.get("/excel", {
    responseType: "blob",
    params: {
      searchedObject,
    },
  });
  return response;
};
