import React, { useEffect, useState } from 'react';

import { Col, message, Row, Table, Button, Space, Badge, Modal } from 'antd';

import { getColumnSearchProps } from '../../../helpers/tableSearch';
import { buildImageURL } from '../../../helpers/functions';
import { BarChartOutlined, DeleteOutlined, PlusCircleFilled } from '@ant-design/icons';
import { IMailing, MailingStatus } from '../../../types/mailings';
import { fetchMailings, deleteMailings } from '../../../api/mailings';
import { mailingStatusesNames } from '../../../constants';
import { deleteCategories } from '../../../api/categories';

const MailingsTable: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [mailings, setMailings] = useState<IMailing[]>([]);

  const [searchedColumn, setSearchedColumn] = useState();
  const [searchText, setSearchText] = useState('');

  const updateData = async () => {
    setLoading(true);

    try {
      const mailings = await fetchMailings();
      setMailings(mailings);
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось получить данные');
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    updateData();
  }, []);

  const onDelete = async (ids) => {
    try {
      await deleteMailings(ids);
      message.success('Рассылка успешно удалена!');
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось удалить рассылку');
    }
    finally {
      updateData();
    }
  };

  const getBadgeStatus = (status: MailingStatus) => {
    const colors = {
      [MailingStatus.FINISHED]: 'success',
      [MailingStatus.IN_PROGRESS]: 'processing',
      [MailingStatus.PLANNED]: 'cyan',
      [MailingStatus.NEW]: 'lime',
      [MailingStatus.FAILED]: 'error',
      default: 'default'
    };
    return colors[status] ?? colors.default;
  };

  const columns = [
    {
      title: 'Действие',
      dataIndex: 'action',
      key: 'action',
      render: (_, mailing) => (
        <Space>
          <Button
            danger
            icon={<DeleteOutlined />}
            disabled={mailing.status !== MailingStatus.PLANNED}
            onClick={() => onDelete(mailing._id)}
          >
            Удалить
          </Button>
        </Space>
      )
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status - b.status,
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      render: (status) => (
        <Badge
          status={getBadgeStatus(status) as any}
          text={mailingStatusesNames[status]}
          style={{ width: 120 }}
        />
      )
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => a.createdAt - b.createdAt,
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
      showSorterTooltip: false,
      render: (createdAt) => createdAt ? new Date(createdAt).toLocaleString() : '—'
    },
    {
      title: 'Дата запуска',
      dataIndex: 'timestamp',
      key: 'timestamp',
      sorter: (a, b) => a.timestamp - b.timestamp,
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      render: (timestamp, { createdAt }) => timestamp ? new Date(timestamp).toLocaleString() : new Date(createdAt).toLocaleString()
    },
    {
      title: 'Текст',
      dataIndex: 'text',
      key: 'text',
      sorter: (a, b) => a.text?.localeCompare(b.text),
      ...getColumnSearchProps('text', setSearchText, setSearchedColumn)
    },
    {
      title: 'Текст кнопки',
      dataIndex: 'buttonTitle',
      key: 'buttonTitle',
      sorter: (a, b) => a.buttonTitle?.localeCompare(b.buttonTitle),
      ...getColumnSearchProps('buttonTitle', setSearchText, setSearchedColumn)
    },
    {
      title: 'Ссылка кнопки',
      dataIndex: 'buttonUrl',
      key: 'buttonUrl',
      sorter: (a, b) => a.buttonUrl?.localeCompare(b.buttonUrl),
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      render: (buttonUrl) => <a href={buttonUrl}>{buttonUrl}</a> ?? '—',
      ...getColumnSearchProps('buttonUrl', setSearchText, setSearchedColumn)
    },
    {
      title: 'Картинки',
      dataIndex: 'imageUrls',
      key: 'imageUrls',
      render: (_, { _id, imageUrls }) => (
        imageUrls?.length
          ? <>{imageUrls.map((image, i) => <a href={image}>Картинка {i + 1} <br/></a>)}</>
          : '—'
      )
    }
  ];

  return (
    <Row justify="center">
      <Col span={24}>
        <div style={{ marginBottom: 16 }}>
          <Space size="small">
            <Button
              type="primary"
              icon={<PlusCircleFilled />}
              href="/create-mailing"
            >
              Создать рассылку
            </Button>
          </Space>
        </div>

        <Table
          loading={loading}
          //@ts-ignore
          columns={columns}
          //@ts-ignore
          dataSource={mailings}
          rowKey="_id"
          locale={{
            filterReset: 'Сбросить',
            filterConfirm: 'ОК',
            emptyText: 'Ничего не найдено'
          }}
          scroll={{ x: true }}
        />
      </Col>
    </Row>
  );
};

export default MailingsTable;
