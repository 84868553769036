import React from 'react';
import { Form, Input, message, Modal, Select } from 'antd';
import { createAdmin } from '../../../api/admins';

interface ICreatorProps {
  visible: boolean;
  onCancel?: () => any;
  afterSubmit?: () => any;
}

const AdminCreator: React.FC<ICreatorProps> = (props) => {
  const [form] = Form.useForm();

  const onSubmit = async ({ username, password }: any) => {
    try {
      await createAdmin({ username, password });
      message.success('Админ успешно создан!');

      form.resetFields();
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось создать админа');
    }
    finally {
      props.afterSubmit?.call(null);
      props.onCancel?.call(null);
    }
  };

  return (
    <Modal
      title="Создание админа"
      className="AdminCreator"
      okText="Создать"
      cancelText="Отменить"
      destroyOnClose={true}
      visible={props.visible}
      onOk={form.submit}
      onCancel={props.onCancel}
    >
      <Form
        form={form}
        initialValues={{ remember: false }}
        onFinish={onSubmit}
        labelCol={{ span: 5 }}
        labelAlign="left"
      >
        <Form.Item
          name="username"
          label="Логин"
          rules={[
            {
              required: true,
              message: 'Введите логин админа!'
            }
          ]}
        >
          <Input placeholder="Введите логин админа" />
        </Form.Item>

        <Form.Item
          name="password"
          label="Пароль"
          rules={[
            {
              required: true,
              message: 'Введите пароль админа!'
            }
          ]}
        >
          <Input type="password" placeholder="Введите пароль админа" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AdminCreator;
