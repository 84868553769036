export enum MailingStatus {
  PLANNED = 1,
  NEW = 2,
  IN_PROGRESS = 3,
  FINISHED = 4,
  FAILED = 5
}

export interface IMailing {
  _id: string;
  status: MailingStatus;
  chatIds: number[];
  timestamp?: number;
  createdAt?: number;
  text?: string;
  imageUrls?: string[];
  buttonUrl?: string;
  buttonTitle?: string;
}

export interface ICreateMailing {
  categories?: string[];
  chats?: string[];
  timestamp?: number;
  text: string;
  imageUrls?: string[];
  buttonUrl?: string;
  buttonTitle?: string;
}
