import React, { ChangeEvent, useEffect, useState } from "react";
import { message, Table, Space, Button, Typography, Select } from "antd";
import { getColumnSearchProps } from "../../../helpers/tableSearch";
import { IChat } from "../../../types/chats";
import {
  fetchChats,
  deleteChats,
  updateChat,
  fetchChatOptions,
  importChats,
  exportChats,
} from "../../../api/chats";
import { DeleteOutlined } from "@ant-design/icons";

const ChatsTable: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [basises, setBasises] = useState<String[]>([]);
  const [products, setProducts] = useState<String[]>([]);
  const [chats, setChats] = useState<IChat[]>();

  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchObject, setSearchObject] = useState({});

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onEdit = async (id: string, data: Partial<IChat>) => {
    try {
      await updateChat(id, data);
    } catch (err) {
      console.error(err);
      message.error("Не вдалось  внести зміни");
    } finally {
      updateData();
    }
  };

  useEffect(() => {
    updateData();
  }, []);

  const updateData = async (newSerachObject = searchObject) => {
    setLoading(true);
    try {
      const { basises, products } = await fetchChatOptions();
      setBasises(basises);
      setProducts(products);
      const chats = await fetchChats(newSerachObject);
      setChats(chats);
    } catch (err) {
      console.error(err);
      message.error("Не удалось загрузить данные");
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      await deleteChats(selectedRowKeys);

      setSelectedRowKeys([]);
      message.success("Чаты успешно удалены!");
    } catch (err) {
      console.error(err);
      message.error("Не удалось удалить чаты");
    } finally {
      updateData();
    }
  };

  const columns = [
    {
      title: "Назва",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title?.localeCompare(b.title),
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
      ...getColumnSearchProps("title", setSearchText, setSearchedColumn),
      onFilter: () => true,
    },
    {
      title: "ChatID",
      dataIndex: "chatId",
      key: "chatId",
      sorter: (a, b) => a.chatId - b.chatId,
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: "Broker",
      dataIndex: "broker",
      key: "broker",
      sorter: (a, b) =>a.broker?.localeCompare(b.broker),
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
      render: (_, { _id, broker = "" }) => (
        <Typography.Text
          editable={{
            onChange: (broker) => onEdit(_id, { broker }),
          }}
        >
          {broker}
        </Typography.Text>
      ),
      ...getColumnSearchProps("broker", setSearchText, setSearchedColumn),
      onFilter: () => true,
    },
    {
      title: "Компанія",
      dataIndex: "company",
      key: "company",
      sorter: (a, b) =>a.company?.localeCompare(b.company),
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
      render: (_, { _id, company = "" }) => (
        <Typography.Text
          editable={{
            onChange: (company) => onEdit(_id, { company }),
          }}
        >
          {company}
        </Typography.Text>
      ),
      ...getColumnSearchProps("company", setSearchText, setSearchedColumn),
      onFilter: () => true,
    },
    {
      title: "Код",
      dataIndex: "code",
      key: "code",
      sorter: (a, b) => a.code?.localeCompare(b.code),
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
      render: (_, { _id, code = "" }) => (
        <Typography.Text
          editable={{
            onChange: (code) => onEdit(_id, { code }),
          }}
        >
          {code}
        </Typography.Text>
      ),
      ...getColumnSearchProps("code", setSearchText, setSearchedColumn),
      onFilter: () => true,
    },
    {
      title: "Тип",
      dataIndex: "type",
      key: "type",
      sorter: (a, b) =>a.type?.localeCompare(b.type),
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
      render: (_, { _id, type = "" }) => (
        <Typography.Text
          editable={{
            onChange: (type) => onEdit(_id, { type }),
          }}
        >
          {type}
        </Typography.Text>
      ),
      ...getColumnSearchProps("type", setSearchText, setSearchedColumn),
      onFilter: () => true,
    },
    {
      title: "Базис",
      dataIndex: "basis",
      key: "basis",
      sorter: (a, b) => a.basis - b.basis,
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
      render: (_, { _id, basis = "" }) => (
        <Select
          style={{ minWidth: "100px" }}
          mode="multiple"
          loading={loading}
          defaultValue={basis ?? ""}
          notFoundContent="Не вдалося отримати дані"
          onChange={(basis) => onEdit(_id, { basis })}
        >
          @ts-ignore
          <Select.Option key={"null"} value={""}>
            {}
          </Select.Option>
          {basises?.map((value) => {
            return (
              <Select.Option key={value.toString()} value={value.toString()}>
                {value}
              </Select.Option>
            );
          })}
        </Select>
      ),
      ...getColumnSearchProps("basis", setSearchText, setSearchedColumn),
      onFilter: () => true,
    },
    {
      title: "Товар",
      dataIndex: "product",
      key: "product",
      sorter: (a, b) => a.product - b.product,
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
      render: (_, { _id, product = "" }) => (
        <Select
          style={{ minWidth: "100px" }}
          mode="multiple"
          loading={loading}
          defaultValue={product ?? ""}
          notFoundContent="Не вдалося отримати дані"
          onChange={(product) => onEdit(_id, { product })}
        >
          @ts-ignore
          <Select.Option key={"null"} value={""}>
            {}
          </Select.Option>
          {products?.map((value) => {
            return (
              <Select.Option key={value.toString()} value={value.toString()}>
                {value}
              </Select.Option>
            );
          })}
        </Select>
      ),
      ...getColumnSearchProps("type", setSearchText, setSearchedColumn),
      onFilter: () => true,
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys) => setSelectedRowKeys(keys),
  };

  const hasSelected = selectedRowKeys.length > 0;

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const handleFileDownload = async () => {
    try {
      const response = await importChats(searchObject);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "chats.xlsx");
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
      alert("File download failed");
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setSelectedFile(file);
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
    
      try {
        await exportChats(selectedFile);

        message.success("Оновлення успішно завершено!");
      } catch (err) {
        console.error(err);
        message.error("Оновлення не було успішним");
      } finally {
        updateData();
      }
    }
  };

  useEffect(() => {
    if(searchedColumn){
      const newSerachObject = { ...searchObject, [searchedColumn]: searchText }
      setSearchObject(newSerachObject);
      updateData(newSerachObject)
    }
  }, [searchedColumn, searchText]);
  return (
    <div className="ChatsTable">
      <div
        style={{
          marginBottom: 16,
        }}
      >
        <Space
          size="small"
          style={{
            marginBottom: 16,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            danger
            type="primary"
            onClick={onDelete}
            disabled={!hasSelected}
            icon={<DeleteOutlined />}
          >
            Удалить
          </Button>
          <div style={{}}>
            <input type="file" accept=".xlsx" onChange={handleFileChange} />
            <Button onClick={handleUpload} style={{ marginRight: 10 }}>
              Upload
            </Button>
            <Button
              danger
              className="ExportButton"
              type="primary"
              onClick={handleFileDownload}
              style={{
                background: "#4fb54f",
                borderColor: "#489622",
              }}
            >
              Download
            </Button>
            <span style={{ marginLeft: 8 }}>
              {hasSelected && `Выбрано ${selectedRowKeys.length} чат(ов)`}
            </span>
          </div>
        </Space>
      </div>

      <Table
        style={{
          overflow: "scroll",
          background: "#fff"
        }}
        loading={loading}
        //@ts-ignore
        columns={columns}
        dataSource={chats}
        rowSelection={rowSelection}
        pagination={{ showSizeChanger: false }}
        rowKey="_id"
        locale={{ emptyText: "Ничего не найдено" }}
      />
    </div>
  );
};

export default ChatsTable;
