import api from './api';
import { IBid } from '../types/bids';
import { debug } from 'console';

export const fetchBids = async (searchObject = {}): Promise<IBid[]> => {
  const { data } = await api.get('/bids', {
    params: {
      searchObject
    }
  });
  return data.bids;
};

export const deleteBids = async (ids: string[]): Promise<void> => {
  await api.delete('/bids', {
    params: ids,
  })
}


export const importBids = async (searchedObject: any): Promise<any> => {
  const response = await api.get('/excel-bid', {
    responseType: 'blob',
    params: {
      searchedObject
    }
  });
  return response;
};
