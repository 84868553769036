import React from 'react';
import { Form, Input, message, Modal } from 'antd';
import { createCategory } from '../../../api/categories';

interface ICreatorProps {
  visible: boolean;
  onCancel?: () => any;
  afterSubmit?: () => any;
}

const CategoryCreator: React.FC<ICreatorProps> = (props) => {
  const [form] = Form.useForm();

  const onSubmit = async ({ name }: any) => {
    try {
      await createCategory(name);
      message.success('Категория успешно добавлена!');

      form.resetFields();
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось добавить категорию');
    }
    finally {
      props.afterSubmit?.call(null);
      props.onCancel?.call(null);
    }
  };

  return (
    <Modal
      title="Добавление категории"
      okText="Добавить"
      cancelText="Отменить"
      destroyOnClose={true}
      visible={props.visible}
      onOk={form.submit}
      onCancel={props.onCancel}
    >
      <Form
        form={form}
        onFinish={onSubmit}
        labelCol={{ span: 7 }}
        labelAlign="left"
      >
        <Form.Item
          name="name"
          label="Название"
          rules={[
            {
              required: true,
              message: 'Необходимо ввести название категории!'
            }
          ]}
        >
          <Input placeholder="Введите название категории" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CategoryCreator;
