const config = {
  dev: {
    api: {
      url: 'https://spikebr.co/api'
    },
    static: {
      url: 'https://spikebr.co'
    }
  },
  prod: {
    api: {
      url: 'https://spikebr.co/api'
    },
    static: {
      url: 'https://spikebr.co'
    }
  }
};

export default process.env.NODE_ENV === 'production' ? config.prod : config.dev;