import api from './api';
import { IMailing, ICreateMailing } from '../types/mailings';

export const fetchMailings = async (): Promise<IMailing[]> => {
  const { data } = await api.get('/mailings');
  return data.mailings;
};

export const createMailing = async (data: ICreateMailing): Promise<void> => {
  await api.post('/mailings', data);
};

export const deleteMailings = async (ids: string[]): Promise<void> => {
  await api.delete('/mailings', {
    params: ids,
  });
};
