import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Form, Input, Upload, Button, message as antdMessage, DatePicker, message, Select } from 'antd';
import { apiURL } from '../../../api/api';
import { normFile, buildImageURL } from '../../../helpers/functions';
import { InboxOutlined } from '@ant-design/icons';
import { createMailing } from '../../../api/mailings';
import moment from 'moment';
import { ICreateMailing } from '../../../types/mailings';
import { ICategory } from '../../../types/categories';
import { IChat } from '../../../types/chats';
import { fetchCategories } from '../../../api/categories';
import { fetchChats } from '../../../api/chats';
import TextEditor from './TextEditor';

const Mailing: React.FC = () => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [chats, setChats] = useState<IChat[]>([]);

  const updateData = async () => {
    setLoading(true);

    try {
      const [categories, chats] = await Promise.all([
        fetchCategories(),
        fetchChats({})
      ]);
      setCategories(categories);
      setChats(chats);
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось получить данные');
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    updateData();
  }, []);

  const onSubmit = async ({ categories, chats, text, timestamp, imageUrls, buttonUrl, buttonTitle }: ICreateMailing) => {
    //@ts-ignore
    if (imageUrls?.length > 10) {
      return antdMessage.warn('Невозможно прикрепить более 10 картинок');
    }

    if ((categories?.length && chats?.length) || (!categories?.length && !chats?.length)) {
      return antdMessage.warn('Выберите или чаты, или категории');
    }

    if ((buttonUrl && !buttonTitle) || (!buttonUrl && buttonTitle)) {
      return antdMessage.warn('Если Вы хотите прикрепить кнопку, укажите ссылку и текст кнопки');
    }

    text = text
      ?.replace(/<p(\s?([a-z][a-z0-9]*)[^>]*?(\/?))?>/gm, '')
      .replace(/<\/p>/gm, '\n')
      .replace(/<strong(\s?([a-z][a-z0-9]*)[^>]*?(\/?))?>/gm, '<b>')
      .replace(/<\/strong>/gm, '</b>')
      .replace(/<em(\s?([a-z][a-z0-9]*)[^>]*?(\/?))?>/gm, '<em>')
      .replace(/<code(\s?([a-z][a-z0-9]*)[^>]*?(\/?))?>/gm, '<code>')
      .replace(/&nbsp(;)?/gm, ' ')
      .replace(/<br\/?>/gm, '');

    //@ts-ignore
    timestamp = timestamp?.toDate();
    //@ts-ignore
    imageUrls = imageUrls?.map(image => buildImageURL(image.response.filename));

    categories = categories?.map(str => str.split('__')[1]);
    chats = chats?.map(str => str.split('__')[1]);

    try {
      const body = Object.assign(
        { text, imageUrls, buttonUrl, buttonTitle },
        categories?.length ? { categories } : null,
        chats?.length ? { chats } : null,
        timestamp ? { timestamp } : null
      );

      await createMailing(body);

      antdMessage.destroy();
      antdMessage.success('Рассылка успешно создана');

      form.resetFields();
    }
    catch (err) {
      console.error(err);
      antdMessage.destroy();
      antdMessage.error('Не удалось создать рассылку');
    }
  };

  const selectAllChats = () => {
    form.setFields([
      {
        name: 'chats',
        value: chats.map(({ _id, title }) => [title, _id].join('__'))
      }
    ]);
  };

  return (
    <Row justify="center" align="middle" className="FormContainer">
      <Col xs={24} sm={20} md={16} lg={12}>
        <Typography.Title level={2} style={{ textAlign: 'center' }}>
          Рассылка
        </Typography.Title>

        <Form
          form={form}
          onFinish={onSubmit}
          labelCol={{ span: 7 }}
          labelAlign="left"
        >
          <h3>Выберите категории</h3>
          <Form.Item name="categories">
            <Select
              loading={loading}
              mode="multiple"
              defaultValue={[]}
              style={{ width: 320 }}
              placeholder="Выберите категории чатов для рассылки..."
            >
              {
                categories?.map(category => (
                  <Select.Option value={[category.name, category._id].join('__')} key={category._id}>
                    {category.name}
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>

          <h3>или чаты <Button type="link" onClick={selectAllChats}>Выбрать все чаты</Button></h3>
          <Form.Item name="chats">
            <Select
              loading={loading}
              mode="multiple"
              defaultValue={[]}
              style={{ width: 320 }}
              placeholder="Выберите чаты для рассылки..."
            >
              {
                chats?.map(chat => (
                  <Select.Option value={[chat.title, chat._id].join('__')} key={chat._id}>
                    {chat.title}
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>

          <h3>Введите текст</h3>
          <Form.Item
            name="text"
            rules={[
              {
                required: true,
                message: 'Введите текст рассылки!'
              }
            ]}
          >
            <TextEditor />
          </Form.Item>

          <Form.Item
            name="buttonUrl"
            rules={[
              {
                pattern: /^http(s)?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)+$/,
                message: 'Некорректный формат ссылки!'
              }
            ]}
          >
            <Input placeholder="Введите ссылку..." />
          </Form.Item>

          <Form.Item name="buttonTitle">
            <Input placeholder="Введіть текст кнопки с ссылкой..." />
          </Form.Item>

          <Form.Item>
            <Form.Item name="imageUrls" valuePropName="imageUrls" getValueFromEvent={normFile} noStyle>
              <Upload.Dragger
                accept=".png,.jpg"
                name="image"
                action={`${apiURL}/image`}
                withCredentials
                multiple={true}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Нажмите на эту область или перетяните сюда изображение</p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>

          <Form.Item
            name="timestamp"
            rules={[
              {
                validator: (_, value) => {
                  return !value || (value?.valueOf() > Date.now())
                    ? Promise.resolve()
                    : Promise.reject(new Error('Вы выбрали дату, которая уже прошла!'));
                }
              }
            ]}
          >
            <DatePicker
              showTime
              showNow={false}
              disabledDate={d => !d || d.isBefore(moment().subtract(1, 'days'))}
              placeholder="Дата и время рассылки (сейчас)"
              style={{ width: 250 }}
            />
          </Form.Item>

          <Form.Item>
            <Button block type="primary" htmlType="submit">
              Создать рассылку
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default Mailing;
