import api from './api';
import { ICategory } from '../types/categories';

export const fetchCategories = async (): Promise<ICategory[]> => {
  const { data } = await api.get('/categories');
  return data.categories;
};

export const createCategory = async (name: string): Promise<void> => {
  await api.post('/categories', { name });
};

export const editCategory = async (id: string, data: any): Promise<any> => {
  await api.put('/categories', { id, data });
};

export const deleteCategories = async (ids: string[]): Promise<void> => {
  await api.delete('/categories', {
    params: ids,
  });
};
