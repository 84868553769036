import React, { useEffect, useState } from 'react';

import { Button, Col, message, Row, Space, Table, Tag, Typography } from 'antd';
import { DeleteOutlined, PlusCircleFilled } from '@ant-design/icons';
import AdminCreator from './AdminCreator';

import { deleteAdmins, editAdmin, fetchAdmins } from '../../../api/admins';
import { getColumnSearchProps } from '../../../helpers/tableSearch';
import { IAdmin } from '../../../types/admins';

const AdminsTable: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [admins, setAdmins] = useState<IAdmin[]>();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [creatorVisible, setCreatorVisible] = useState(false);

  const [searchedColumn, setSearchedColumn] = useState();
  const [searchText, setSearchText] = useState('');

  const updateData = async () => {
    setLoading(true);

    try {
      const admins = await fetchAdmins();
      setAdmins(admins);
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось получить админов');
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    updateData();
  }, []);

  const onEdit = async (id: string, data: Partial<IAdmin>) => {
    try {
      await editAdmin(id, data);
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось изменить админа');
    }
    finally {
      updateData();
    }
  };

  const onDelete = async () => {
    try {
      await deleteAdmins(selectedRowKeys);

      setSelectedRowKeys([]);
      message.success('Админы успешно удалены!');
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось удалить админов');
    }
    finally {
      updateData();
    }
  };

  const columns = [
    {
      title: 'Логин',
      dataIndex: 'username',
      key: 'username',
      sorter: (a, b) => a.username.localeCompare(b.username),
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      render: (_, { _id, username }) => (
        <Typography.Text editable={{
          onChange: username => onEdit(_id, { username })
        }}>
          {username}
        </Typography.Text>
      ),
      ...getColumnSearchProps('username', setSearchText, setSearchedColumn)
    },
    {
      title: 'Пароль',
      dataIndex: 'password',
      key: 'password',
      render: (_, { _id }) => (
        <Typography.Text editable={{
          onChange: password => onEdit(_id, { password })
        }} />
      )
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: keys => setSelectedRowKeys(keys)
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div className="AdminsTable">
      <Row justify="center">
        <Col sm={24} xl={12}>
          <div style={{ marginBottom: 16 }}>
            <Space size="small">
              <Button
                type="primary"
                icon={<PlusCircleFilled />}
                onClick={() => setCreatorVisible(true)}
                disabled={hasSelected}
              >
                Создать
              </Button>

              <Button
                danger
                type="primary"
                onClick={onDelete}
                disabled={!hasSelected}
                icon={<DeleteOutlined />}
              >
                Удалить
              </Button>

              <span style={{ marginLeft: 8 }}>
                {hasSelected && `Выбрано ${selectedRowKeys.length} админ(ов)`}
              </span>
            </Space>
          </div>

          <Table
            loading={loading}
            rowSelection={rowSelection}
            //@ts-ignore
            columns={columns}
            dataSource={admins}
            rowKey="_id"
            locale={{
              filterReset: 'Сбросить',
              filterConfirm: 'ОК',
              emptyText: 'Ничего не найдено'
            }}
          />

          <AdminCreator
            visible={creatorVisible}
            onCancel={() => setCreatorVisible(false)}
            afterSubmit={updateData}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AdminsTable;
