import React, { useEffect, useState } from "react";
import { message, Table, Space, Button, Typography } from "antd";
import { getColumnSearchProps } from "../../../helpers/tableSearch";
import { IBid } from "../../../types/bids";
import { fetchBids, deleteBids, importBids } from "../../../api/bids";
import { DeleteOutlined } from "@ant-design/icons";

const BidsTable: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [bids, setBids] = useState<IBid[]>();

  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchText, setSearchText] = useState("");

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchObject, setSearchObject] = useState({});

  useEffect(() => {
    updateData();
  }, []);

  const updateData = async (newSerachObject = searchObject) => {
    setLoading(true);
    try {
      const bids = await fetchBids(newSerachObject);
      setBids(bids);
    } catch (err) {
      console.error(err);
      message.error("Не удалось загрузить данные");
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      await deleteBids(selectedRowKeys);

      setSelectedRowKeys([]);
      message.success("Успешно удалены!");
    } catch (err) {
      console.error(err);
      message.error("Не удалось удалить");
    } finally {
      updateData();
    }
  };

  const handleFileDownload = async () => {
    try {
      const response = await importBids(searchObject);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "bids.xlsx");
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
      alert("File download failed");
    }
  };

  const columns = [
    {
      title: "Дата",
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => a.date?.localeCompare(b.date),
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
      render: (_, { _id, date }) => {
        return date.split("-")[0];
      },
      ...getColumnSearchProps("date", setSearchText, setSearchedColumn),
      onFilter: () => true,
    },
    {
      title: "Товар",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) =>a.name?.localeCompare(b.name),
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
      ...getColumnSearchProps("name", setSearchText, setSearchedColumn),
      onFilter: () => true,
    },
    {
      title: "Базис",
      dataIndex: "basis",
      key: "basis",
      sorter: (a, b) =>a.basis?.localeCompare(b.basis),
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
      ...getColumnSearchProps("basis", setSearchText, setSearchedColumn),
      onFilter: () => true,
    },
    {
      title: "Призначення",
      dataIndex: "allocation",
      key: "allocation",
      sorter: (a, b) => a.allocation?.localeCompare(b.allocation),
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
      render: (_, { _id, allocation = "" }) => (
        <Typography.Text>{allocation}</Typography.Text>
      ),
      ...getColumnSearchProps("allocation", setSearchText, setSearchedColumn),
      onFilter: () => true,
    },
    {
      title: "Транспорт",
      dataIndex: "transport",
      key: "transport",
      sorter: (a, b) =>a.transport?.localeCompare(b.transport),
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
      render: (_, { _id, transport = "" }) => (
        <Typography.Text>{transport}</Typography.Text>
      ),
      ...getColumnSearchProps("transport", setSearchText, setSearchedColumn),
      onFilter: () => true,
    },
    {
      title: "Період",
      dataIndex: "period",
      key: "period",
      sorter: (a, b) =>a.period?.localeCompare(b.period),
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
      render: (_, { _id, period = "" }) => (
        <Typography.Text>{period}</Typography.Text>
      ),
      ...getColumnSearchProps("period", setSearchText, setSearchedColumn),
      onFilter: () => true,
    },
    {
      title: "Ціна",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) =>a.price?.localeCompare(b.price),
      sortDirections: ["descend", "ascend"],
      showSorterTooltip: false,
      ...getColumnSearchProps("basis", setSearchText, setSearchedColumn),
      onFilter: () => true,
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys) => setSelectedRowKeys(keys),
  };

  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    if(searchedColumn){
      const newSerachObject = { ...searchObject, [searchedColumn]: searchText }
      setSearchObject(newSerachObject);
      updateData(newSerachObject)
    }
  }, [searchedColumn, searchText]);

  return (
    <div className="BidsTable">
      <div style={{ marginBottom: 16 }}>
        <Space size="small" style={{
              display: "flex",
              justifyContent: "space-between"
        }}>
          <Button
            danger
            type="primary"
            onClick={onDelete}
            disabled={!hasSelected}
            icon={<DeleteOutlined />}
          >
            Удалить
          </Button>
          <span style={{ marginLeft: 8 }}>
            {hasSelected && `Выбрано ${selectedRowKeys.length} bids(ов)`}
          </span>
          <Button
            danger
            className="ExportButton"
            type="primary"
            onClick={handleFileDownload}
            style={{
              background: "#4fb54f",
              borderColor: "#489622",
            }}
          >
            Download
          </Button>
        </Space>
      </div>

      <Table
        style={{
          overflow: "scroll",
          background: "#fff",
        }}
        loading={loading}
        //@ts-ignore
        columns={columns}
        dataSource={bids}
        rowSelection={rowSelection}
        pagination={{ showSizeChanger: false }}
        rowKey="_id"
        locale={{ emptyText: "Ничего не найдено" }}
      />
    </div>
  );
};

export default BidsTable;
