import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import Login from '../Login/Login';
import NotFound from '../common/NotFound';
import ProtectedRoute from './ProtectedRoute';
import Dashboard from '../Dashboard/Dashboard';
import MailingCreator from '../Dashboard/Mailing/MailingCreator';
import ChatsTable from '../Dashboard/Chats/ChatsTable';
import CategoriesTable from '../Dashboard/Categories/CategoriesTable';
import MailingsTable from '../Dashboard/Mailing/MailingsTable';
import AdminsTable from '../Dashboard/Admins/AdminsTable';
import BidsTable from '../Dashboard/Bids/BidsTable';

interface IRouterProps {
  authorized: boolean;
}

const Router: React.FC<IRouterProps> = ({ authorized }) => {
  return (
    <BrowserRouter>
      <Switch>
        <ProtectedRoute exact path="/" authorized={authorized} component={() => (
          <Redirect to="/chats" />
        )} />
        <ProtectedRoute exact path="/chats" authorized={authorized} component={() => (
          <Dashboard>
            <ChatsTable />
          </Dashboard>
        )} />
        <ProtectedRoute exact path="/bids" authorized={authorized} component={() => (
          <Dashboard>
            <BidsTable />
          </Dashboard>
        )} />
        <ProtectedRoute exact path="/categories" authorized={authorized} component={() => (
          <Dashboard>
            <CategoriesTable />
          </Dashboard>
        )} />
        <ProtectedRoute exact path="/mailings" authorized={authorized} component={() => (
          <Dashboard>
            <MailingsTable />
          </Dashboard>
        )} />
        <ProtectedRoute exact path="/create-mailing" authorized={authorized} component={() => (
          <Dashboard>
            <MailingCreator />
          </Dashboard>
        )} />
        <ProtectedRoute exact path="/admins" authorized={authorized} component={() => (
          <Dashboard>
            <AdminsTable />
          </Dashboard>
        )} />
        <Route exact path="/login" component={Login} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
