import React, { useEffect, useState } from 'react';
import { message, Table, Space, Button, Select, Typography } from 'antd';
import { getColumnSearchProps } from '../../../helpers/tableSearch';
import { ICategory } from '../../../types/categories';
import { fetchCategories, deleteCategories, editCategory } from '../../../api/categories';
import { PlusCircleFilled, DeleteOutlined } from '@ant-design/icons';
import CategoryCreator from './CategoryCreator';
import { IChat } from '../../../types/chats';
import { fetchChats } from '../../../api/chats';

const CategoriesTable: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<ICategory[]>();
  const [chats, setChats] = useState<IChat[]>();

  const [searchedColumn, setSearchedColumn] = useState();
  const [searchText, setSearchText] = useState('');

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [creatorVisible, setCreatorVisible] = useState(false);

  useEffect(() => {
    updateData();
  }, []);

  const updateData = async () => {
    setLoading(true);

    try {
      const [categories, chats] = await Promise.all([
        fetchCategories(),
        fetchChats({})
      ]);
      setCategories(categories);
      setChats(chats);
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось загрузить данные');
    }
    finally {
      setLoading(false);
    }
  };

  const onEdit = async (id: string, data: Partial<ICategory>) => {
    if (data.chats) {
      //@ts-ignore
      data.chats = data.chats.map(str => str.split('__')[1]);
    }

    try {
      await editCategory(id, data);
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось изменить категорию');
    }
    finally {
      updateData();
    }
  };

  const onDelete = async () => {
    try {
      await deleteCategories(selectedRowKeys);

      setSelectedRowKeys([]);
      message.success('Категории успешно удалены!');
    }
    catch (err) {
      console.error(err);
      message.error('Не удалось удалить категории');
    }
    finally {
      updateData();
    }
  };

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['descend', 'ascend'],
      showSorterTooltip: false,
      render: (_, { _id, name }) => (
        <Typography.Text editable={{
          onChange: name => onEdit(_id, { name })
        }}>
          {name}
        </Typography.Text>
      ),
      ...getColumnSearchProps('name', setSearchText, setSearchedColumn)
    },
    {
      title: 'Чаты',
      dataIndex: 'chats',
      key: 'chats',
      showSorterTooltip: false,
      render: (_, category) => (
        <Select
          loading={loading}
          mode="multiple"
          defaultValue={category.chats.map(({ _id, title }) => [title, _id].join('__'))}
          style={{ width: 220, marginBottom: 12 }}
          onChange={(chats) => onEdit(category._id, { chats })}
          placeholder="Выбрать чаты..."
        >
          {
            chats?.map(chat => (
              <Select.Option value={[chat.title, chat._id].join('__')} key={chat._id}>
                {chat.title}
              </Select.Option>
            ))
          }
        </Select>
      ),
      ...getColumnSearchProps('name', setSearchText, setSearchedColumn)
    }
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: keys => setSelectedRowKeys(keys)
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <div className="ChatsTable">
      <div style={{ marginBottom: 16 }}>
        <Space size="small">
          <Button
            type="primary"
            icon={<PlusCircleFilled />}
            onClick={() => setCreatorVisible(true)}
            disabled={hasSelected}
          >
            Добавить
          </Button>

          <Button
            danger
            type="primary"
            onClick={onDelete}
            disabled={!hasSelected}
            icon={<DeleteOutlined />}
          >
            Удалить
          </Button>

          <span style={{ marginLeft: 8 }}>
					  {hasSelected && `Выбрано ${selectedRowKeys.length} категорий(я)`}
					</span>
        </Space>
      </div>

      <Table
        loading={loading}
        //@ts-ignore
        columns={columns}
        dataSource={categories}
        rowSelection={rowSelection}
        pagination={{ showSizeChanger: false }}
        rowKey="_id"
        locale={{ emptyText: 'Ничего не найдено' }}
      />

      <CategoryCreator
        visible={creatorVisible}
        onCancel={() => setCreatorVisible(false)}
        afterSubmit={updateData}
      />
    </div>
  );
};

export default CategoriesTable;
